import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'

const SliderImage = (props) => {
  return (
    <>
      <div className="shed-slider--image" style={{ backgroundImage: `url('${props.image}')`}} />
    </>
  )
}

class ShedSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: null,
      thumbnails: null
    }

    this.sliderPrev = this.sliderPrev.bind(this)
    this.sliderNext = this.sliderNext.bind(this)
  }

  sliderPrev() {
    this.imagesSlider.slickPrev();
  }

  sliderNext() {
    this.imagesSlider.slickNext();
  }

  componentDidMount() {
    this.setState({
      images: this.imagesSlider,
      thumbnails: this.thumbsSlider
    })
  }

  render() {
    const { images } = this.props
    return (
      <>
        <div className="shed-slider--nav prev" onClick={ this.sliderPrev }/>
        <div className="shed-slider--wrapper">
          <Slider
            className="shed-slider--images"
            arrows={ false }
            speed={ 500 }
            asNavFor={ this.state.thumbnails }
            ref={ c => (this.imagesSlider = c) }
          >
            {images.map(({ image }, index) => (
              <SliderImage image={ image } key={ index } />
            ))}
          </Slider>

          <Slider
            className="shed-slider--thumbnails"
            arrows={ false }
            asNavFor={ this.state.images }
            slidesToShow={ 5 }
            swipeToSlide={ true }
            focusOnSelect={ true }
            variableWidth={ true }
            ref={ c => (this.thumbsSlider = c) }
          >
            {images.map(({ image }, index) => (
              <div className="shed-slider--thumbnail" key={ index } style={{ backgroundImage: `url('${ image }')`}}>
                <img src={ image } alt={ index } height={ 50 } />
              </div>
            ))}
          </Slider>
        </div>

        <div className="shed-slider--nav next" onClick={ this.sliderNext } />
      </>
    )
  }
}

export default ShedSlider
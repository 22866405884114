import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

class ColorPalette extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      color: null
    }

    this.selectColor = this.selectColor.bind(this)
    this.deselectColor = this.deselectColor.bind(this)
  }

  selectColor(name) {
    this.setState({
      color: name
    })
  }

  deselectColor() {
    this.setState({
      color: null
    })
  }

  render() {
    const { data: { allColorsYaml: { edges } } } = this.props
    return (
      <>
        <Tabs className="colors">
          <TabList className="colors-tab-list">
            {edges.map(({ node: { category } }) => (
              <Tab
                className="colors-tab"
                selectedClassName="colors-tab__selected"
                disabledClassName="colors-tab__disabled"
                key={ category }
              >
                { category }
              </Tab>
            ))}
          </TabList>

          {edges.map(({ node: { id, subcategories } }) => (
            <TabPanel
              className="colors-tab-panel"
              selectedClassName="colors-tab-panel__selected"
              key={ id }
              >
                {subcategories.map(({ title, colors }) => (
                  <div key={ title }>
                    <div className="colors-title">Available {title}</div>
                    <div className="colors-list">
                      {colors.map(({ name, code, image, shadow }) => {
                        let style = {}
                        if (image) {
                          style = {
                            'backgroundImage': `url('${image}')`
                          }
                        } else if (code) {
                          style = {
                            'backgroundColor': code
                          }
                        }
                        return (
                        <div className="colors-list--color" key={ name } onClick={() => this.selectColor(name)}>
                          <div className={shadow ? 'colors-list--color__icon shadowed' : 'colors-list--color__icon'} style={ style } />
                          <div className="colors-list--color__name">{ name }</div>
                        </div>
                      )})}
                    </div>
                  </div>
                ))}
            </TabPanel>
          ))}
        </Tabs>

        {this.state.color && (
          <div className="colors-selected">
            <span>Color:</span> { this.state.color }

            <div className="colors-deselect" onClick={ this.deselectColor } />
          </div>
        )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allColorsYaml {
          edges {
            node {
              id
              category
              subcategories {
                title
                colors {
                  name
                  code
                  image
                  shadow
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ColorPalette data={data} {...props} />}
  />
)
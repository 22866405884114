import React from 'react'

const ContactForm = () => (
  <form
    className="contacts-form"
    name="contact-form"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    method="post"
    action="#"
  >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact-form" />
    <input className="contacts-form--input" type="text" name="name" maxLength="100" placeholder="Name" required />
    <input className="contacts-form--input" type="email" name="email" maxLength="100" placeholder="Email Address" required />
    <textarea className="contacts-form--input textarea" name="message" maxLength="2000" placeholder="Message" />
    <button className="contacts-form--submit" type="submit">Send</button>
  </form>
)

export default ContactForm
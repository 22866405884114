import React from 'react'
import PropTypes from 'prop-types'

const Accessory = ({ data }) => (
  <div className="accessory">
    <div className="accessory-image" style={{backgroundImage: `url(${data.image})`}}></div>
    <div className="accessory-content">
      <h3 className="accessory-title">
        {data.title}
      </h3>
      <div className="accessory-description">
        {data.description}
      </div>
    </div>
  </div>
)

Accessory.propTypes = {
  data: PropTypes.object.isRequired
}

export default Accessory
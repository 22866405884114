import React from 'react'
import Layout from '../components/layout'
import ShedTabs from '../components/shedTabs'
import AccessoriesList from '../components/accessoriesList'
import GallerySlider from '../components/gallerySlider'
import ContactForm from '../components/contactForm'

const IndexPage = () => (
  <Layout>
    <section className="banner">
      <div className="banner-content">
        <div className="banner-description">
          Nowhere to put your outdoor equipment?<br/>
          Always tripping over bikes?<br/>
          It's time to call Shed Express
        </div>
        <a className="banner-cta" href="docs/Shed_Express.pdf" target="_blank">
          Download Brochure
        </a>
      </div>
    </section>

    <section className="section" id="sheds">
      <h2 className="section-heading">
        Sheds
      </h2>
      <div className="sheds-content">
        <ShedTabs />
      </div>
    </section>

    <section className="section" id="accessories">
      <h2 className="section-heading">
        Accessories
      </h2>

      <div className="accessories-content">
        <AccessoriesList />
      </div>
    </section>

    <section className="section" id="gallery">
      <h2 className="section-heading">
        Gallery
      </h2>

      <div className="gallery-content">
        <GallerySlider />
      </div>
    </section>

    <section className="section" id="contact">
      <h2 className="section-heading">
        Contact Us
      </h2>

      <div className="contacts-content">
        <div className="contacts-main">
          <div className="contacts-list">
            <div className="contacts-item">
              <img className="contacts-icon" src="images/phone.svg" alt="Phone icon" />
              <a href="tel:+7327021622">(732) 702-1622</a>
            </div>
            <div className="contacts-item">
              <img className="contacts-icon" src="images/marker.svg" alt="Map marker icon" />
              <div>
                Shed Express<br />
                915 Bennetts Mills Rd,<br />
                Po box 1168<br />
                Jackson, NJ 08527
              </div>
            </div>
          </div>
          <div className="contacts-about">
            <h2>About Us:</h2>
            Nowhere to put your lawnmowers? Generators? Grills? <br />
            Always tripping over bikes? It’s time for a new shed. <br />
            Call us. We’ll have your shed shipped and built in no time at all.
            Thats why we call it express. Shed Express. <br />
            <b>Give us a call we do it all. </b>
          </div>
        </div>

        <ContactForm />
      </div>
    </section>
  </Layout>
)

export default IndexPage

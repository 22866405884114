import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Accessory from './accessory'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'

const AccessoriesList = () => (
  <StaticQuery
    query={graphql`
      {
        allAccessoriesYaml {
          edges {
            node {
              id
              title
              description
              image
            }
          }
        }
      }
    `}
    render={({ allAccessoriesYaml: { edges } }) => {
      const settings = {
        infinite: true,
        speed: 500,
        responsive: [
          {
            breakpoint: 10000,
            settings: 'unslick'
          },
          {
            breakpoint: 767,
            settings: {
              slidesPerRow: 1,
              rows: 1,
            }
          }
        ]
      }
      return (
        <>
          <Slider className="accessories-list mobile" {...settings}>
            {edges.map(({ node }) => (
              <Accessory data={ node } key={ node.id } />
            ))}
          </Slider>

          <div className="accessories-list desktop">
            {edges.map(({ node }) => (
              <Accessory data={ node } key={ node.id } />
            ))}
          </div>
        </>
      )
    }}
  />
)

export default AccessoriesList
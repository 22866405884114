import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ShedSlider from './shedSlider'
import ColorPalette from './colorPalette'

class ShedTabs extends React.Component {
  render() {
    const { data: { Sheds, Sliders  } } = this.props
    return (
      <Tabs className="shed-tabs">
        <TabList className="shed-tabs--tab-list">
          {Sheds.edges.map(({ node: { title } }) => (
            <Tab
              className="shed-tabs--tab"
              selectedClassName="shed-tabs--tab__selected"
              disabledClassName="shed-tabs--tab__disabled"
              key={ title }
            >
              { title }
            </Tab>
          ))}
        </TabList>
        {Sheds.edges.map(({ node: { id, title, description } }) => (
          <TabPanel
            className="shed-tabs--tab-panel"
            selectedClassName="shed-tabs--tab-panel__selected"
            key={ id }
            >
              <div className="shed-tab--description">{ description }</div>
              <div className="shed-tab--info">
                <div className="shed-tab--images">
                  {Sliders.edges.filter(({ node: { shed } }) => shed === title).map(({ node: { id, images } }) => (
                    <ShedSlider images={ images } key={ id } />
                  ))}
                </div>
                <div className="shed-tab--colors">
                  <ColorPalette />
                </div>
              </div>
          </TabPanel>
        ))}
      </Tabs>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        Sheds: allShedsYaml {
          edges {
            node {
              id
              title
              description
            }
          }
        }
        Sliders: allSlidersYaml {
          edges {
            node {
              id
              shed
              images {
                image
              }
            }
          }
        }
      }
    `}
    render={data => <ShedTabs data={data} {...props} />}
  />
)
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Lightbox from 'react-image-lightbox'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'react-image-lightbox/style.css'

class GallerySlider extends React.Component {
  constructor(props) {
    super(props)

    this.sliderPrev = this.sliderPrev.bind(this)
    this.sliderNext = this.sliderNext.bind(this)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  sliderPrev() {
    this.slider.slickPrev();
  }

  sliderNext() {
    this.slider.slickNext();
  }

  render() {
    const { data: { allFile: { edges } } } = this.props
    const { photoIndex, isOpen } = this.state;
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      slidesPerRow: 3,
      rows: 2,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesPerRow: 2,
            rows: 3,
          }
        }
      ]
    }

    return (
      <>
        <div className="gallery-nav prev" onClick={ this.sliderPrev } />
        <div className="gallery-slides--wrapper">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {edges.map(({ node : { id, childImageSharp: { fixed } } }, index) => (
              <div key={ id }>
                <img
                  className="gallery-slide--image"
                  src={ fixed.src }
                  alt="Gallery Element"
                  onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="gallery-nav next" onClick={ this.sliderNext } />

        {isOpen && (
          <Lightbox
            mainSrc={ edges[photoIndex].node.childImageSharp.fluid.src }
            nextSrc={ edges[(photoIndex + 1) % edges.length].node.childImageSharp.fluid.src }
            prevSrc={ edges[(photoIndex + edges.length - 1) % edges.length].node.childImageSharp.fluid.src }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + edges.length - 1) % edges.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % edges.length,
              })
            }
          />
        )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: {
          absolutePath:{
            regex:"/(gallery)/.*.(jpg)|(jpeg)|(png)$/i"
          }
        }) {
          edges {
            node {
              id
              childImageSharp {
                fixed(width: 290, height: 170, cropFocus: CENTER, rotate: 0) {
                  src
                }
                fluid(rotate: 0) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => <GallerySlider data={data} {...props} />}
  />
)